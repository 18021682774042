import { useEffect, useState } from "react";
import { FXBModel } from "./FBXModel";
import { useParams } from "react-router-dom";
import { useLocalArticles } from "../hooks/useLocal";
//import { useAirtableArticles } from "../hooks/useAirtable";
import ReactMarkdown from "react-markdown";
import { Button, Modal, Image } from "semantic-ui-react";
import { CardsGrid } from "./CardsGrid";

export const Article = () => {
  const data = useLocalArticles();
  //const data = useAirtableArticles();

  const { id } = useParams();

  const articleid = parseInt(id as string);

  const [language, setLanguage] = useState(
    localStorage.getItem("language") || "EN"
  );

  useEffect(() => {
    function checkLanguage() {
      const language = localStorage.getItem("language");

      if (language) {
        setLanguage(language);
      }
    }

    window.addEventListener("storage", checkLanguage);

    return () => {
      window.removeEventListener("storage", checkLanguage);
    };
  }, []);

  const [article, setArticle] = useState<any>();

  useEffect(() => {
    if (data.length > 0) {
      setArticle(data.find((article: any) => article.id === articleid));
    }
  }, [data, id]);

  const arClass = language === "AR" ? "arabic" : "";

  let artifactCredits = "";

  if (article && article.StoryArtistName !== article.ArtifactArtistName) {
    artifactCredits = `Artifact by ${article.ArtifactArtistName}, ${article.ArtifactArtistCountry}`;
  }

  const ShowImage = ({ id, numerator }: { id: string; numerator: string }) => {
    const [open, setOpen] = useState(false);

    let image = `/images/${id}`;
    if (numerator != "") {
      image += `_${numerator}`;
    }
    image += `.jpg`;

    return (
      <Modal
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        open={open}
        trigger={
          <div className="article-image">
            <img
              src={image}
              onError={(e) => {
                (e.target as HTMLTextAreaElement).style.display = "none";
              }}
            />
          </div>
        }
      >
        <Modal.Content>
          <Image src={image} />
        </Modal.Content>
        <Modal.Actions>
          <Button color="black" onClick={() => setOpen(false)}>
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };

  return (
    <>
      {article && (
        <div className="ui section">
          <div className="ui grid">
            <div className="sixteen wide column">
              <div className={"article-header " + arClass}>
                {article[`Title${language}`] && (
                  <h1>{article[`Title${language}`]}</h1>
                )}
                <div className="author">
                  <strong>{article.StoryArtistName}</strong>,{" "}
                  {article.StoryArtistCountry}
                </div>
                <div className="artifact-credits">{artifactCredits}</div>
              </div>
            </div>
          </div>
          <div className="ui container">
            {article.id === 10 && (
              <>
                <FXBModel />
                <br />
                <br />
              </>
            )}

            {id && id !== "5" && <ShowImage id={id} numerator={""} />}

            {id && id === "5" && (
              <div className="sideBySide">
                <ShowImage id={id} numerator={""} />
                <ShowImage id={id} numerator={"1"} />
              </div>
            )}

            {article[`ArtifactTitle${language}`] && (
              <div
                className="artifact-title"
                style={language == "AR" ? { direction: "rtl" } : {}}
              >
                {article[`ArtifactTitle${language}`]}
              </div>
            )}
            {article[`ArtifactCaption${language}`] && (
              <div
                className="artifact-caption"
                style={language == "AR" ? { direction: "rtl" } : {}}
              >
                <ReactMarkdown>
                  {article[`ArtifactCaption${language}`]}
                </ReactMarkdown>
              </div>
            )}
            <div
              className="article"
              style={language == "AR" ? { direction: "rtl" } : {}}
            >
              <div>
                <ReactMarkdown>
                  {article[`Story${language}`] &&
                    article[`Story${language}`].replace(/_/g, "")}
                </ReactMarkdown>
              </div>

              {article.id !== 10 &&
                article.id !== 5 &&
                article.id !== 2 &&
                [1, 2, 3, 4, 5].map((i) => (
                  <div className="article-image">
                    <ShowImage id={id + ""} numerator={i + ""} />
                  </div>
                ))}

              {article.id === 2 && (
                <div className="article-image">
                  <br />
                  <video
                    controls
                    style={{ maxHeight: 600 }}
                    poster={"/images/2.jpg"}
                  >
                    <source src="/videos/connect_video.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              )}

              {article.id === 10 && <CardsGrid article={article} />}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
