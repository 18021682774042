import { useLoader, Canvas, useFrame } from "@react-three/fiber";
import { OrbitControls, PerspectiveCamera } from "@react-three/drei";
import { Suspense, useEffect, useRef } from "react";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { MobileView } from "react-device-detect";
import * as THREE from "three";

const Scene = () => {
  const fbx = useLoader(FBXLoader, "/the-hive-lab.fbx");
  const mesh = useRef<THREE.Object3D>();

  useEffect(() => {
    if (mesh.current) {
      const box = new THREE.Box3().setFromObject(mesh.current);
      const center = box.getCenter(new THREE.Vector3());

      mesh.current.position.x -= center.x;
      mesh.current.position.y -= center.y;
      mesh.current.position.z -= center.z;
    }
  }, [mesh, fbx]);

  useFrame(({ clock }) => {
    if (mesh.current) {
      const box = new THREE.Box3().setFromObject(mesh.current);
      const center = box.getCenter(new THREE.Vector3());

      mesh.current.position.x -= center.x;
      mesh.current.position.y -= center.y;
      mesh.current.position.z -= center.z;
      mesh.current.rotation.y = clock.getElapsedTime() * 0.2 - center.y; // Adjust the rotation speed here
    }
  });

  return <primitive ref={mesh} object={fbx} scale={7} />;
};

export const FXBModel = () => {
  return (
    <div className="three-model">
      <Canvas style={{ backgroundColor: "#f1f1f1" }}>
        <PerspectiveCamera makeDefault position={[0, 5, 15]} />
        <Suspense fallback={null}>
          <ambientLight intensity={0.5} />
          <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} />
          <pointLight position={[-10, -10, -10]} />
          <Scene />
          <OrbitControls enableZoom={false} />
        </Suspense>
      </Canvas>
      {/* <MobileView>
        <a className="augmented-reality" href="./the-hive-lab.usdz">
          See in Augmented Reality
        </a>
      </MobileView> */}
    </div>
  );
};
