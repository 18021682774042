import { useEffect, useState } from "react";
import { Language } from "./Language";
//import { useAirtableIntroductions } from "../hooks/useAirtable";
import { useLocalIntroductions } from "../hooks/useLocal";
import { isMobile } from "react-device-detect";

export const Header = () => {
  const [language, setLanguage] = useState(
    localStorage.getItem("language") || "english"
  );

  useEffect(() => {
    function checkLanguage() {
      const language = localStorage.getItem("language");

      if (language) {
        setLanguage(language);
      }
    }

    window.addEventListener("storage", checkLanguage);

    return () => {
      window.removeEventListener("storage", checkLanguage);
    };
  }, []);

  //const data = useAirtableIntroductions();
  const data = useLocalIntroductions();

  return (
    <>
      <div className="top ui borderless menu navbar full-width">
        <a className="item home" href="/">
          {!isMobile ? (
            <strong>
              {data
                .filter(
                  (introduction) =>
                    introduction.Name === `Introduction${language}`
                )
                .map((introduction, index) => (
                  <div key={index}>{introduction.Heading}</div>
                ))}
            </strong>
          ) : (
            <strong>FWI</strong>
          )}
        </a>
        <div className="right item">
          <Language />
          <a href="https://solferinoacademy.com">
            <img
              src="../solferino-academy-logo.png"
              alt="logo"
              className={"solferino-logo"}
            />
          </a>
        </div>
      </div>
    </>
  );
};
