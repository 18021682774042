import { useEffect, useState } from "react";
import { useAirtableIntroductions } from "../hooks/useAirtable";

export const Footer = () => {
  const [language, setLanguage] = useState(
    localStorage.getItem("language") || "english"
  );

  useEffect(() => {
    function checkLanguage() {
      const language = localStorage.getItem("language");

      if (language) {
        setLanguage(language);
      }
    }

    window.addEventListener("storage", checkLanguage);

    return () => {
      window.removeEventListener("storage", checkLanguage);
    };
  }, []);

  const data = useAirtableIntroductions();

  return (
    <>
      <div className="computer tablet only row">
        <div className="top ui borderless menu navbar full-width">
          <a className="item home" href="/">
            {data
              .filter(
                (introduction) =>
                  introduction.Name === `Introduction${language}`
              )
              .map((introduction, index) => (
                <div key={index}>{introduction.Heading}</div>
              ))}
            &nbsp;&copy; 2023
          </a>
          <div className="right item">
            <a href="https://solferinoacademy.com">
              <img
                src="../solferino-academy-logo.png"
                alt="logo"
                className={"solferino-logo"}
              />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
