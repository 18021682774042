import ReactDOM from "react-dom/client";
import "./index.css";
import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Article } from "./components/Article";
import { CardsTesellated } from "./components/CardsTesellated";
import { IntroHeader } from "./components/IntroHeader";
import { PublishToLocalFromAirtable } from "./components/PublishToLocalFromAirtable";
import { MoreStories } from "./components/MoreStories";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <main>
        <Header />
        <IntroHeader />
        <CardsTesellated />
        <Footer />
      </main>
    ),
  },
  {
    path: "/article/:id",
    element: (
      <main>
        <Header />
        <Article />
        <CardsTesellated />
        <Footer />
      </main>
    ),
  },
  {
    path: "/more-stories",
    element: (
      <main>
        <Header />
        <MoreStories />
        <Footer />
      </main>
    ),
  },
  {
    path: "/publish",
    element: <PublishToLocalFromAirtable />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(<RouterProvider router={router} />);
