import { useState } from "react";
import { Button, Modal, Image } from "semantic-ui-react";

export const SmallCard = ({ index }: { index: number }) => {
  const [open, setOpen] = useState(false);

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={
        <div
          className="smallcard-img"
          style={{ backgroundImage: `url(/images/10_${index + 1}.jpg)` }}
        ></div>
      }
    >
      <Modal.Content>
        <Image src={`/images/10_${index + 1}.jpg`} />
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={() => setOpen(false)}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
