import { useEffect, useState } from "react";

type Props = {
  article: any;
  fontSize?: string;
};

export const Card = ({ article, fontSize }: Props) => {
  const [language, setLanguage] = useState(
    localStorage.getItem("language") || "EN"
  );

  const image = `/images/${article.id}.jpg`;

  let articleLink = `/article/${article.id}`;
  let target = "_self";

  if (article.id === 12) {
    articleLink = `https://solferinoacademy.com`;
    target = "_blank";
  }

  useEffect(() => {
    function checkLanguage() {
      const language = localStorage.getItem("language");

      if (language) {
        setLanguage(language);
      }
    }

    window.addEventListener("storage", checkLanguage);

    return () => {
      window.removeEventListener("storage", checkLanguage);
    };
  }, []);

  return (
    <div className="column story-landing-page-article">
      <a href={articleLink} target={target}>
        <div
          className="square-card"
          style={{
            backgroundImage: `url('${image}')`,
          }}
        >
          <p style={{ fontSize }}>{article[`Question${language}`]}</p>
        </div>
      </a>
    </div>
  );
};
