import { useState } from "react";

export const Language = () => {
  const [language, setLanguage] = useState(
    localStorage.getItem("language") || "EN"
  );

  const handleLanguage = (language: string) => {
    setLanguage(language);
    localStorage.setItem("language", language);
    window.dispatchEvent(new Event("storage"));
  };

  return (
    <div className="language-wrapper">
      <div className="languages">
        <div
          onClick={() => handleLanguage("EN")}
          className={language === "EN" ? "lang lang-selected" : "lang"}
        >
          EN
        </div>
        <div
          onClick={() => handleLanguage("FR")}
          className={language === "FR" ? "lang lang-selected" : "lang"}
        >
          FRA
        </div>
        <div
          onClick={() => handleLanguage("ES")}
          className={language === "ES" ? "lang lang-selected" : "lang"}
        >
          ESP
        </div>
        <div
          onClick={() => handleLanguage("AR")}
          className={language === "AR" ? "lang lang-selected" : "lang"}
        >
          AR
        </div>
      </div>
    </div>
  );
};
