import Articles from "../data/Articles.json";
import Introductions from "../data/Introductions.json";
import { useEffect, useState } from "react";

export const useLocalArticles = () => {
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    setData(Articles);
  }, []);

  return data;
};

export const useLocalIntroductions = () => {
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    setData(Introductions);
  }, []);

  return data;
};
