import { SmallCard } from "./SmallCard";

export const CardsGrid = ({ article }: any) => {
  const arr = [];
  for (let i = 0; i <= 7; i++) {
    arr.push(i);
  }

  return (
    <div className="ui grid cardsgrid">
      <div className="ui eight column stackable grid">
        {arr.map((_image, index) => {
          return (
            <div className="column" key={index}>
              <SmallCard index={index} />
            </div>
          );
        })}
      </div>
    </div>
  );
};
