import { FXBModel } from "./FBXModel";
import React, { useEffect, useState } from "react";

//import { useAirtableIntroductions } from "../hooks/useAirtable";
import ReactMarkdown from "react-markdown";
import { useLocalIntroductions } from "../hooks/useLocal";

export const IntroHeader = () => {
  //const data = useAirtableIntroductions();
  const data = useLocalIntroductions();

  const [language, setLanguage] = useState(
    localStorage.getItem("language") || "EN"
  );

  useEffect(() => {
    function checkLanguage() {
      const language = localStorage.getItem("language");

      if (language) {
        setLanguage(language);
      }
    }

    window.addEventListener("storage", checkLanguage);

    return () => {
      window.removeEventListener("storage", checkLanguage);
    };
  }, []);

  const arClass = language === "AR" ? "arabic" : "";

  return (
    <div className="ui section intro-header">
      <div className="ui stackable grid">
        <div
          className={"nine wide column noselect"}
          style={language == "AR" ? { direction: "rtl" } : {}}
        >
          {data
            .filter(
              (introduction) => introduction.Name === `Introduction${language}`
            )
            .map((introduction, index) => (
              <div key={index}>
                <h1>{introduction.Heading}</h1>
                <ReactMarkdown>{introduction.Body}</ReactMarkdown>
              </div>
            ))}
        </div>
        <div className="seven wide column no-padding">
          <FXBModel />
        </div>
      </div>
    </div>
  );
};
