import { useEffect, useState } from "react";
import { AirtableData } from "../types/types";

var Airtable = require("airtable");
Airtable.configure({
  endpointUrl: "https://api.airtable.com",
  apiKey:
    "patKR8WfQQsIkNERN.5a5529cd1faf7ab8ea89789a746692d525564a710870a3a1ea8d508f979f1d4c",
});
var base = Airtable.base("appgQhA3vVXavRRWh");

export const useAirtableArticles = () => {
  const [data, setData] = useState<AirtableData[]>([]);

  useEffect(() => {
    base("Articles")
      .select({
        view: "Grid view",
      })
      .eachPage(
        function page(records: any, fetchNextPage: any) {
          records.forEach(function (record: any) {
            setData((prevData) => [...prevData, record.fields]);
          });
          fetchNextPage();
        },
        function done(err: any) {
          if (err) {
            console.error(err);
            return;
          }
        }
      );
  }, []);

  return data;
};

export const useAirtableIntroductions = () => {
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    base("Introductions")
      .select({
        view: "Grid view",
      })
      .eachPage(
        function page(records: any, fetchNextPage: any) {
          records.forEach(function (record: any) {
            setData((prevData) => [...prevData, record.fields]);
          });
          fetchNextPage();
        },
        function done(err: any) {
          if (err) {
            console.error(err);
            return;
          }
        }
      );
  }, []);

  return data;
};
