import { AirtableData } from "../types/types";
import { useLocalArticles } from "../hooks/useLocal";
//import { useAirtableArticles } from "../hooks/useAirtable";
import { Card } from "./Card";

export const CardsTesellated = () => {
  const dataUnsorted: AirtableData[] = useLocalArticles();

  const filteredData = dataUnsorted.filter(
    (article) => Object.keys(article).length > 0
  );

  const data = filteredData.sort((a, b) => {
    const sortOrderA = a.SortOrder !== undefined ? a.SortOrder : 0;
    const sortOrderB = b.SortOrder !== undefined ? b.SortOrder : 0;
    return sortOrderA - sortOrderB;
  });

  console.log(data);

  return (
    <div className="ui section intro-header">
      <div className="ui grid">
        <div className="ui cards-stories">
          <div className="ui three column stackable grid">
            {data
              .filter(
                (article, index) =>
                  index >= 0 && index <= 2 && article.FrontPage
              )
              .map((article: AirtableData, index: number) => {
                console.log(article.FrontPage);
                return <Card key={index} article={article} />;
              })}
          </div>
          <div className="ui three column stackable grid">
            {data
              .filter(
                (article, index) =>
                  index >= 3 && index <= 5 && article.FrontPage
              )
              .map((article: AirtableData, index: number) => {
                return <Card key={index} article={article} />;
              })}
          </div>
          <div className="ui three column stackable grid">
            {data
              .filter(
                (article, index) =>
                  index >= 6 && index <= 8 && article.FrontPage
              )
              .map((article: AirtableData, index: number) => {
                return <Card key={index} article={article} />;
              })}
          </div>
          <div className="ui three column stackable grid">
            {data
              .filter(
                (article, index) =>
                  index >= 9 && index <= 11 && article.FrontPage
              )
              .map((article: AirtableData, index: number) => {
                return <Card key={index} article={article} />;
              })}
          </div>
        </div>
      </div>
    </div>
  );
};
