/**
 * This component uses useAirtable to fetch data from Airtable and then POST that data to the address localhost:3222 with the route /publish with variables articles and introductions
 * Let's be sure to use state so that the fetch only happens when the data comes through
 */

import { useState } from "react";
import {
  useAirtableArticles,
  useAirtableIntroductions,
} from "../hooks/useAirtable";

export const PublishToLocalFromAirtable = () => {
  const articles = useAirtableArticles();
  const introductions = useAirtableIntroductions();
  const [published, setPublished] = useState(false);

  const publish = async () => {
    console.log(articles, introductions);
    const response = await fetch("http://localhost:3222/publish", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ articles, introductions }),
    });
    const data = await response.json();
    console.log(data);
    setPublished(true);
  };

  return (
    <div>
      <h1>Publish to Local</h1>
      <button onClick={publish}>Publish</button>
      {published && <p>Published!</p>}
    </div>
  );
};
